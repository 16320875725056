'use client';

import { lazy, useEffect } from 'react';
import { useSetAtom } from 'jotai';
import Logo from '@/public/icon/logo';
import { Android, Apple, Microsoft, Terminal } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Btn } from '@/components/layout/Btn';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';
const Slogan = lazy(() => import('@/public/etc/slogan'));
export default function Page() {
  const router = useRouter();
  useEffect(() => {
    router.prefetch('/signin');
  }, []);
  return <>
            <Top data-sentry-element="Top" data-sentry-source-file="page.tsx" />
            <Content data-sentry-element="Content" data-sentry-source-file="page.tsx" />
        </>;
}
function Top() {
  const t = useTranslations();
  const btnStyle = {
    maxWidth: '348px',
    width: '100%',
    borderRadius: '3px'
  };
  return <div data-sentry-component="Top" data-sentry-source-file="page.tsx">
            <div className="text-center mt-[73px] mb-[20px] text-color text-[15px]">
                {t.rich('home.otu-description', {
        bold: chunks => <span className="font-bold">{chunks}</span>
      })}
            </div>

            <div className="text-center">
                <Link href="/signin" className="inline-flex justify-center items-center h-[48px] bg-text-color inverted-text-color text-[17px] font-bold hover:opacity-70" style={btnStyle} prefetch={true} data-sentry-element="Link" data-sentry-source-file="page.tsx">
                    {t('home.start')}
                </Link>
            </div>

            <div className="mt-[55px]">
                <div className="flex flex-col items-center gap-[11px]">
                    <div className="text-[13px] mb-[4px] opacity-50" style={btnStyle}>
                        {t('home.app-download')}
                    </div>
                    <Btn onClick={() => {
          alert(t('home.coming-soon'));
        }} data-sentry-element="Btn" data-sentry-source-file="page.tsx">
                        <Apple className="w-[20px]" data-sentry-element="Apple" data-sentry-source-file="page.tsx" />
                        App Store
                    </Btn>

                    <Btn onClick={() => {
          alert(t('home.coming-soon'));
        }} data-sentry-element="Btn" data-sentry-source-file="page.tsx">
                        <Android className="w-[20px] mr-1" data-sentry-element="Android" data-sentry-source-file="page.tsx" />
                        Google Play
                    </Btn>
                    <div className="h-4"></div>
                    <Btn onClick={() => {
          alert(t('home.coming-soon'));
        }} data-sentry-element="Btn" data-sentry-source-file="page.tsx">
                        <Terminal className="mr-1 w-[20px]" data-sentry-element="Terminal" data-sentry-source-file="page.tsx" />
                        CLI
                    </Btn>

                    <Btn onClick={() => {
          alert(t('home.coming-soon'));
        }} data-sentry-element="Btn" data-sentry-source-file="page.tsx">
                        Desktop app
                    </Btn>
                </div>
            </div>

            <div className="flex justify-center mt-[90px] text-[25px]">
                <Slogan className="fill-text-color" width="200" data-sentry-element="Slogan" data-sentry-source-file="page.tsx"></Slogan>
            </div>
        </div>;
}
function Content() {
  const t = useTranslations();
  const accordionStyle = {
    boxShadow: 'none',
    margin: 0,
    borderBottom: '1px solid var(--border-color)',
    '&:before': {
      display: 'none'
    },
    '&.Mui-expanded': {
      margin: 0,
      borderBottom: '1px solid var(--border-color)'
    },
    '&:last-of-type': {
      borderBottom: 'none'
    }
  };
  const iconStyle = {
    fontSize: '1.2rem',
    opacity: 0.5
  };
  return <>
            <div className="mt-[94px] ">
                <Accordion sx={accordionStyle} data-sentry-element="Accordion" data-sentry-source-file="page.tsx">
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={iconStyle} />} data-sentry-element="AccordionSummary" data-sentry-source-file="page.tsx">
                        <div className="text-[19px] text-color">{t('home.features.title')}</div>
                    </AccordionSummary>
                    <AccordionDetails data-sentry-element="AccordionDetails" data-sentry-source-file="page.tsx">
                        <Pa data-sentry-element="Pa" data-sentry-source-file="page.tsx">
                            <span className="text-[15px] font-bold">· {t('home.features.cross-platform.title')}</span>
                            <div className="ml-2">{t('home.features.cross-platform.desc')}</div>
                        </Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="page.tsx">
                            <span className="text-[15px] font-bold">· {t('home.features.ai-chatbot.title')}</span>
                            <div className="ml-2">{t('home.features.ai-chatbot.desc')}</div>
                        </Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="page.tsx">
                            <span className="text-[15px] font-bold">· {t('home.features.editing.title')}</span>
                            <div className="ml-2">
                                {t('home.features.editing.desc')}
                            </div>
                        </Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="page.tsx">
                            <span className="text-[15px] font-bold">· {t('home.features.quick-note.title')}</span>
                            <div className="ml-2">
                                {t('home.features.quick-note.desc')}
                            </div>
                        </Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="page.tsx">
                            <span className="text-[15px] font-bold">· {t('home.features.auto-title.title')}</span>
                            <div className="ml-2">
                                {t('home.features.auto-title.desc')}
                            </div>
                        </Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="page.tsx">
                            <span className="text-[15px] font-bold">· {t('home.features.ocr.title')}</span>
                            <div className="ml-2">
                                {t('home.features.ocr.desc')}
                            </div>
                        </Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="page.tsx">
                            <span className="text-[15px] font-bold">· {t('home.features.fast-loading.title')}</span>
                            <div className="ml-2">{t('home.features.fast-loading.desc')}</div>
                        </Pa>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={accordionStyle} data-sentry-element="Accordion" data-sentry-source-file="page.tsx">
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={iconStyle} />} data-sentry-element="AccordionSummary" data-sentry-source-file="page.tsx">
                        <div className="text-[19px] text-color">{t('home.pricing.title')}</div>
                    </AccordionSummary>
                    <AccordionDetails data-sentry-element="AccordionDetails" data-sentry-source-file="page.tsx">
                        <Pa data-sentry-element="Pa" data-sentry-source-file="page.tsx">{t('home.pricing.free-note')}</Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="page.tsx">{t('home.pricing.free-credits')}</Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="page.tsx">{t('home.pricing.paid-credits')}</Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="page.tsx">{t('home.pricing.price-notice')}</Pa>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={accordionStyle} data-sentry-element="Accordion" data-sentry-source-file="page.tsx">
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={iconStyle} />} data-sentry-element="AccordionSummary" data-sentry-source-file="page.tsx">
                        <div className="text-[19px] text-color">{t('home.about-otu.title')}</div>
                    </AccordionSummary>
                    <AccordionDetails data-sentry-element="AccordionDetails" data-sentry-source-file="page.tsx">
                        <Pa data-sentry-element="Pa" data-sentry-source-file="page.tsx">{t('home.about-otu.desc-1')}</Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="page.tsx">{t('home.about-otu.desc-2')}</Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="page.tsx">{t('home.about-otu.desc-3')}</Pa>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={accordionStyle} data-sentry-element="Accordion" data-sentry-source-file="page.tsx">
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={iconStyle} />} data-sentry-element="AccordionSummary" data-sentry-source-file="page.tsx">
                        <div className="text-[19px] text-color">{t('home.about-opentutorials.title')}</div>
                    </AccordionSummary>
                    <AccordionDetails data-sentry-element="AccordionDetails" data-sentry-source-file="page.tsx">
                        <Pa data-sentry-element="Pa" data-sentry-source-file="page.tsx">{t('home.about-opentutorials.desc-1')}</Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="page.tsx">{t('home.about-opentutorials.desc-2')}</Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="page.tsx">
                            {t.rich('home.about-opentutorials.desc-3', {
              openTutorialsLink: chunks => <a className="underline" href="https://opentutorials.org">
                                        {chunks}
                                    </a>,
              otuLink: chunks => <a className="underline" href="https://otu.ai">
                                        {chunks}
                                    </a>
            })}
                        </Pa>
                        <Pa data-sentry-element="Pa" data-sentry-source-file="page.tsx">{t('home.about-opentutorials.desc-4')}</Pa>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="mt-[120px] text-center text-[15px]">
                {t.rich('home.footer.slogan', {
        br: () => <br />
      })}
                <div className="mt-[15px] text-[12px] opacity-40">{t('home.footer.organization')}</div>
            </div>
        </>;
}
function Pa({
  children
}: {
  children: React.ReactNode;
}) {
  return <div className="pt-0 py-3 text-[15px] opacity-70 text-color" data-sentry-component="Pa" data-sentry-source-file="page.tsx">{children}</div>;
}